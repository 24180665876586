#check-group {
  animation: 0.32s ease-in-out 1s check-group;
  transform-origin: center;
}

#check {
  animation: 0.34s cubic-bezier(0.65, 0, 1, 1) 0.8s forwards check;
  stroke-dasharray: 0, 75px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

@keyframes check {
  from {
    stroke-dasharray: 0, 75px;
  }
  to {
    stroke-dasharray: 75px, 75px;
  }
}
@keyframes check-group {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  to {
    transform: scale(1);
  }
}
